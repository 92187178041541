import { FunctionComponent, memo } from "react";
import { Url } from "@edgetier/types";
import urljoin from "url-join";
import { useDispatch } from "react-redux";
import { DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";

import useSimpleQuery from "queries/use-simple-query";
import { IBrand } from "types-for/brands/brands.types";
import { toastOperations } from "redux/modules/toast";

import { IProps } from "./brand-details-field.types";

/**
 * Request and display a brand in a table row.
 * @param props.brandId The brand ID of the brand to display.
 */
const BrandDetailsField: FunctionComponent<IProps> = ({ brandId, isLoading: isBrandIdLoading }) => {
    const dispatch = useDispatch();

    const { data, isLoading } = useSimpleQuery<IBrand>(urljoin(Url.Brands, String(brandId)), {
        onError: (serverError) => dispatch(toastOperations.showServerErrorToast("Brand Request Failed", serverError)),
        staleTime: 1000 * 60 * 5,
        enabled: typeof brandId !== "undefined",
    });

    return (
        <tr>
            <td>
                <DetailsFieldTitle>Brand</DetailsFieldTitle>
            </td>
            <td>
                <DetailsFieldValue
                    isNull={!isBrandIdLoading && typeof brandId !== "number"}
                    isLoading={isLoading || isBrandIdLoading}
                    copyText={data?.brand}
                >
                    {data?.brand}
                </DetailsFieldValue>
            </td>
        </tr>
    );
};

export default memo(BrandDetailsField);

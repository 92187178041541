export const DEVICE_AND_LOCATION_DETAILS_ENABLED = true;
export const FORMS_PAGE_ENABLED = true;
export const REDACTIONS_ENABLED = true;
export const DELETE_FORMS_ENABLED = false;
export const FORM_EDITOR_CREATE_SELECT_OPTION_ENABLED = true;
export const FORM_EDITOR_TRANSLATIONS_ENABLED = true;
export const PROACTIVE_CHAT_ENABLED = true;
export const MANAGE_USER_STATES_ENABLED = true;
export const TEXT_LOCALISATIONS_ENABLED = true;
export const LIVE_AGENTS_REPORTING_ENABLED = true;
export const LIVE_AGENTS_FORCE_SIGN_OUT_ENABLED = true;
export const CROSS_DOMAIN_CHATS_ENABLED = false;
export const BRANDS_SCREEN_ENABLED = true;

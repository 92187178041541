import AddItemButton from "./add-item-button";
import classNames from "classnames";
import React from "react";
import SelectBulkButtons from "./select-bulk-buttons";
import SelectListTitle from "./select-list-title";
import SelectSelectedList from "./select-selected-list";
import { Box } from "@edgetier/client-components";
import { Button, SpinnerUntil } from "@edgetier/components";
import { doNothing } from "@edgetier/utilities";
import { faCheck, faChevronRight, faQuestionCircle, faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProps } from "./select-menu.types";
import { memo, useCallback } from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import { useState } from "react";
import "./select-menu.scss";

/**
 * Menu to allow users to choose one or more items from a list.
 * @param props.addItemMenu      A menu for adding an item to the select.
 * @param props.children         Optional method to render items.
 * @param props.clear            Method to clear the select.
 * @param props.close            Method to close the menu.
 * @param props.description      Description of items to be selected.
 * @param props.disableMenuItems Whether the menu items should be disabled or not.
 * @param props.getGroup         Getter for an item's group name.
 * @param props.getLabel         Getter for an item's label.
 * @param props.getValue         Getter for an item's value.
 * @param props.isLoading        Optional loading state of the items.
 * @param props.isSearchable     Whether to show a search box or not.
 * @param props.isSingleSelect   Whether one or more items can be selected.
 * @param props.isSourcedSelect  Whether the select requests it's options or gets them passed to it.
 * @param props.message          An optional message to show in the select menu.
 * @param props.onSelectItems    Handler when items are selected.
 * @param props.onInputChange    A function that gets called when the select's input value changes.
 * @param props.items            Array of all options.
 * @param props.placeholder      Search box placeholder text.
 * @param props.selectedValues   Values selected before opening the menu.
 * @returns                      Menu of items to choose.
 */
const SelectMenu = <IItem extends {}, IValue extends {} = string>({
    addItemMenu,
    children,
    clear,
    close,
    description,
    disableMenuItems = false,
    getGroup,
    getLabel,
    getValue,
    isLoading,
    isSearchable,
    isSingleSelect = false,
    isSourcedSelect = false,
    message,
    noItemsFoundLabel,
    onSelectItems,
    onInputChange = doNothing,
    items,
    placeholder,
    selectedValues,
    clearSearchOnSelection = false,
}: IProps<IItem, IValue>) => {
    const {
        addSelectedItem,
        getDropdownProps,
        getSelectedItemProps,
        removeSelectedItem,
        selectedItems,
        setSelectedItems,
    } = useMultipleSelection<IItem>({
        initialSelectedItems: items.filter((item) => (selectedValues ?? []).includes(getValue(item))),
    });

    const [inputValue, setInputValue] = useState(
        isSourcedSelect && selectedItems.length > 0 ? getLabel(selectedItems[0]) : ""
    );

    const notSelectedItems = items.filter((item) => {
        return (
            selectedItems.indexOf(item) < 0 &&
            // If true, it will return all the items that have not been selected. Otherwise, it will do the search.
            (isSourcedSelect || getLabel(item).toLowerCase().trim().includes(inputValue.toLowerCase().trim()))
        );
    });

    /**
     * Select some items and close the menu.
     * @param items Newly selected items.
     */
    const selectAndClose = (items: IItem[]) => {
        close();
        onSelectItems(items.map(getValue), items);
    };

    /**
     * Close the menu and return the selected options.
     */
    const onApply = (currentlySelectedItems: IItem[]) => {
        selectAndClose(currentlySelectedItems);
    };

    /**
     * Select all items.
     */
    const onSelectAll = useCallback(() => {
        setSelectedItems(selectedItems.concat(notSelectedItems));
        setInputValue("");
    }, [notSelectedItems, selectedItems, setInputValue, setSelectedItems]);

    /**
     * Clear all items.
     */
    const onSelectNone = useCallback(() => {
        setSelectedItems([]);
        setInputValue("");
    }, [setSelectedItems]);

    const { getComboboxProps, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectItem } = useCombobox({
        inputValue,
        items: notSelectedItems,
        itemToString: (item) => (item === null ? "" : String(getValue(item))),
        onStateChange: ({ inputValue, type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputChange:
                    // Clear the selection when the user changes the input because the options will change and the
                    // selected item may no longer be valid.
                    if (isSourcedSelect && selectedItems.length > 0) {
                        setSelectedItems([]);
                        clear();
                    }
                    onInputChange(inputValue);
                    setInputValue(inputValue ?? "");
                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (selectedItem) {
                        if (clearSearchOnSelection) {
                            setInputValue("");
                        }

                        if (isSingleSelect) {
                            selectAndClose([selectedItem]);
                        } else {
                            addSelectedItem(selectedItem);
                        }

                        selectItem(null as any);
                    }

                    break;
                default:
                    break;
            }
        },
    });

    return (
        <Box
            className={classNames("select-menu", {
                "select-menu--is-multiple-select": !isSingleSelect,
                "select-menu--is-single-select": isSingleSelect,
                "select-menu--is-searchable": isSearchable,
                "select-menu--is-not-searchable": !isSearchable,
            })}
        >
            <div className="select-menu__input" data-testid="search" {...getComboboxProps()}>
                <div className="field field-inline">
                    <div className="field-inline__icon">
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <input
                        autoFocus
                        autoComplete="off"
                        placeholder={placeholder}
                        type="text"
                        {...getInputProps(getDropdownProps({ preventKeyAction: true }))}
                    />
                </div>
            </div>

            <div className="select-menu__lists">
                <div className="select-menu__list select-menu__list--not-selected">
                    {!isSingleSelect && <SelectListTitle count={notSelectedItems.length} title="Not Selected" />}

                    <div className="select-menu__options">
                        {inputValue.trim().length > 0 && notSelectedItems.length === 0 && !isLoading && (
                            <div className="select-menu__options--empty">
                                {typeof noItemsFoundLabel === "undefined"
                                    ? `No ${description}s found`
                                    : noItemsFoundLabel}
                            </div>
                        )}

                        {typeof message !== "undefined" && (
                            <div className="select-menu__message">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                {message}
                            </div>
                        )}

                        <ul {...getMenuProps()}>
                            <SpinnerUntil data={[]} isReady={!isLoading}>
                                {notSelectedItems.map((item, index) => {
                                    const value = getValue(item);
                                    const key =
                                        typeof value === "object" ? Object.values(value).join() : value.toString();
                                    return (
                                        <li key={key}>
                                            {typeof getGroup === "function" &&
                                                (index === 0 ||
                                                    getGroup(item) !== getGroup(notSelectedItems[index - 1])) && (
                                                    <div
                                                        aria-label={getGroup(item)}
                                                        className="select-menu__group-title"
                                                    >
                                                        {getGroup(item)}
                                                    </div>
                                                )}
                                            <div
                                                aria-label={getLabel(item)}
                                                className={classNames("select-menu__option", {
                                                    "select-menu__option--is-highlighted": highlightedIndex === index,
                                                    "select-menu__option--is-disabled": disableMenuItems,
                                                })}
                                                {...getItemProps({ item, index, disabled: disableMenuItems })}
                                            >
                                                <div className="select-menu__option__label">
                                                    {typeof children === "function" ? children(item) : getLabel(item)}
                                                </div>
                                                {!isSingleSelect && <FontAwesomeIcon icon={faChevronRight} />}
                                            </div>
                                        </li>
                                    );
                                })}
                            </SpinnerUntil>
                        </ul>
                    </div>

                    {typeof addItemMenu !== "undefined" && (
                        <div className="select-menu__add-item">
                            <AddItemButton addItemMenu={addItemMenu} />
                        </div>
                    )}
                </div>

                {!isSingleSelect && (
                    <SelectBulkButtons
                        isDisabled={disableMenuItems}
                        notSelectedItemsCount={notSelectedItems.length}
                        onSelectAll={onSelectAll}
                        onSelectNone={onSelectNone}
                        selectedItemsCount={selectedItems.length}
                    />
                )}

                {!isSingleSelect && (
                    <SelectSelectedList
                        getGroup={getGroup}
                        getLabel={getLabel}
                        getSelectedItemProps={getSelectedItemProps}
                        getValue={getValue}
                        isLoading={isLoading}
                        removeSelectedItem={removeSelectedItem}
                        selectedItems={selectedItems}
                    >
                        {children}
                    </SelectSelectedList>
                )}
            </div>

            {!isSingleSelect && (
                <div className="select-menu__controls">
                    <Button icon={faTimes} onClick={close} styleName="neutral">
                        Cancel
                    </Button>

                    <Button
                        disabled={isLoading}
                        icon={faCheck}
                        onClick={() => onApply(selectedItems)}
                        styleName="positive"
                    >
                        Apply
                    </Button>
                </div>
            )}
        </Box>
    );
};

export default memo(SelectMenu) as typeof SelectMenu;

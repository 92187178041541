enum View {
    AgentHome = "/main/home",
    Authenticate = "/authenticate",
    AuthenticatedRedirect = "/authenticated-redirect",
    CallWallboard = "/main/reporting/call-wallboard",
    CreateEmail = "/main/new-email",
    Chat = "/main/chat",
    DeferredQueries = "/main/deferred-queries",
    LogicFlows = "/main/flows",
    LogicFlow = "/main/flow",
    Login = "/",
    Main = "/main",
    Query = "/main/query",
    QueryHistory = "/main/query-history",
    ProactiveChat = "/main/proactive-chat",
    Reporting = "/main/reporting",
    ReportingAgents = "/main/reporting/agents",
    ReportingChat = "/main/reporting/chat",
    ReportingHistoric = "/main/reporting/historic",
    ReportingQueue = "/main/reporting/queue",
    ReportingPublicChat = "/main/reporting/public-chat",
    ReportingPublicEmail = "/main/reporting/public-email",
    ReportingReports = "/main/reporting/reports",
    Permissions = "/main/permissions",
    Search = "/main/search",
    SearchQueries = "/main/search/queries",
    SearchInteractions = "/main/search/interactions",
    Settings = "/main/settings",
    SettingsBans = "/main/settings/bans",
    SettingsBrands = "/main/settings/brands",
    SettingsChatWidgetAppearances = "/main/settings/chat-widget-appearances",
    SettingsDepartments = "/main/settings/departments",
    SettingsEmailRoutingRules = "/main/settings/email-routing-rules",
    SettingsForms = "/main/settings/forms",
    SettingsLanguages = "/main/settings/languages",
    SettingsSetups = "/main/settings/setups",
    SettingsSkills = "/main/settings/skills",
    SettingsSystem = "/main/settings/system",
    SettingsThemes = "/main/settings/themes",
    SettingsUserStates = "/main/settings/user-states",
    SettingsTextLocalisations = "/main/settings/text-localisations",
    Templates = "/main/templates",
    Users = "/main/users",
}

export default View;

import AttachmentTooltipContent from "components/attachment-tooltip-content";
import axios from "utilities/axios";
import ChatMessage from "shared/chat-messages/chat-message";
import DeleteAttachmentButton from "components/delete-attachment-button";
import InputError from "constants/input-error";
import InteractionEvent from "components/interaction-events/interaction-event";
import RedactAction from "components-for/query-history/redact-action";
import showBlockedAttachmentsError from "utilities/show-blocked-attachments-error";
import UnknownEvent from "components/interaction-events/unknown-event";
import { CHAT_EVENT_DISPLAY } from "./chat-event.constants";
import { ChatEventType, MessageRecipientType } from "@edgetier/types";
import { doNothing } from "@edgetier/utilities";
import { EllipsisMenu } from "@edgetier/client-components";
import { IApplicationState } from "redux/types";
import { IProps } from "./chat-event.types";
import { setupSelectors } from "redux/modules/setup";
import { Tooltip } from "@edgetier/components";
import { useSelector } from "react-redux";
import VerifyPermission from "components-for/verify-permission/verify-permission";
import { Permission } from "@edgetier/types";
import "./chat-event.scss";
import { REDACTIONS_ENABLED } from "constants/feature-flags";

/**
 * Render a chat event. Each will have a specific icon, colour, name and date.
 * @param props.event     Chat event.
 * @param props.showError Method to display an error in a modal.
 * @param messageProps    All other props to pass to the chat message component.
 * @returns               Icon, name, and date of event.
 */
const ChatEvent = ({ event, interactionUrl, isChatCompleted, showError, ...messageProps }: IProps) => {
    const languages = useSelector(({ setup }: IApplicationState) => setupSelectors.getLanguages(setup));

    // Messages are displayed differently than all the other events.
    if (event.chatEventTypeId === ChatEventType.Message) {
        return (
            <ChatMessage
                {...messageProps}
                getAttachment={axios.get}
                highlightSystemMessages={true}
                invalidError={InputError.Invalid}
                isPlainMessage={false}
                isTyping={false}
                languages={languages}
                linkTarget="_blank"
                menu={
                    REDACTIONS_ENABLED ? (
                        <VerifyPermission permission={Permission.RedactContent}>
                            {event.messageRecipientTypeId === MessageRecipientType.Agent &&
                                typeof event.messageId === "number" && (
                                    <EllipsisMenu placement="bottom-start" overflowContainer>
                                        <RedactAction
                                            interactionUrl={interactionUrl}
                                            sections={[
                                                {
                                                    label: "Message",
                                                    property: "message",
                                                    translations: event.translations,
                                                },
                                            ]}
                                            url={`/messages/${event.messageId}`}
                                        />
                                    </EllipsisMenu>
                                )}
                        </VerifyPermission>
                    ) : undefined
                }
                message={event}
                onBlocked={showBlockedAttachmentsError(showError)}
                onSubmit={doNothing}
                requiredError={InputError.Required}
                shouldRenderForm={false}
                showTranslations={false}
                submitLabel="Submit"
                typingIndicatorLabel="Customer is typing..."
                userRecipientTypeId={MessageRecipientType.Agent}
            >
                {(attachment) =>
                    attachment.deleted ? (
                        <AttachmentTooltipContent attachment={attachment} />
                    ) : (
                        <Tooltip
                            content={
                                <div className="delete-button-tooltip">
                                    The attachments of an ongoing chat cannot be deleted.
                                </div>
                            }
                            delayEnter={250}
                            disableTooltip={isChatCompleted}
                            useArrow
                        >
                            <DeleteAttachmentButton attachment={attachment} disabled={!isChatCompleted} />
                        </Tooltip>
                    )
                }
            </ChatMessage>
        );
    }

    // There's a mapping from each event to how it should be displayed. Take that for the current if it's available or,
    // if the backend has added a new event the frontend doesn't know about, display it as an unknown event.
    const chatEventDisplay = CHAT_EVENT_DISPLAY[event.chatEventTypeId];
    return typeof chatEventDisplay === "undefined" ? (
        <UnknownEvent event={event} />
    ) : (
        <InteractionEvent event={event} {...chatEventDisplay} />
    );
};

export default ChatEvent;

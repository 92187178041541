import useShortcutScope from "components-for/shortcuts/use-shortcut-scope";
import logo from "images/icon-colour-light-background.svg";
import AgentHomeLink from "./agent-home-link";
import ChatLink from "./chat-link";
import CreateEmailLink from "./create-email-link";
import DeferredQueriesLink from "./deferred-queries-link";
import QueryLink from "./query-link";
import SignOut from "./sign-out/sign-out";
import TemplatesLink from "./templates-link";
import UsersLink from "./users-link";
import { IProps } from "./navigation.types";
import { Permission } from "@edgetier/types";
import { CategoryNavigation, NavigationLink } from "@edgetier/client-components";
import {
    faAddressBook,
    faBan,
    faBuilding,
    faCalendar,
    faChartBar,
    faLanguage,
    faTrademark,
    faUsers,
    faUserTag,
} from "@fortawesome/pro-solid-svg-icons";
import { faCog, faComment, faCommentLines, faComments, faDesktop, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faFile, faFileChartLine, faGlobeAmericas, faListAlt, faPaintRoller } from "@fortawesome/pro-solid-svg-icons";
import { faSearch, faSitemap, faTags, faWrench } from "@fortawesome/pro-solid-svg-icons";
import View from "constants/view";
import classNames from "classnames";
import { useCurrentUser } from "utilities/use-current-user";
import {
    BRANDS_SCREEN_ENABLED,
    FORMS_PAGE_ENABLED,
    LIVE_AGENTS_REPORTING_ENABLED,
    MANAGE_USER_STATES_ENABLED,
    TEXT_LOCALISATIONS_ENABLED,
} from "constants/feature-flags";
import "./navigation.scss";
import VerifyPermission from "components-for/verify-permission/verify-permission";
import { SETTINGS_PERMISSIONS } from "constants/permission";

/**
 * Display links to other routes. Certain links are only displayed depending on the user's role.
 */
const Navigation = ({
    callFunctionalityEnabled,
    chatFunctionalityEnabled,
    emailFunctionalityEnabled,
    proactiveChatEnabled,
}: IProps) => {
    useShortcutScope();
    const { data: user } = useCurrentUser();

    return (
        <div className="navigation">
            <div
                className={classNames("navigation__logo", {
                    "navigation__logo--inverted": process.env.NODE_ENV !== "production",
                })}
            >
                <img src={logo} alt="EdgeTier" />
            </div>
            <div className="navigation__links">
                <VerifyPermission permission={Permission.HandleInteraction}>
                    <AgentHomeLink />
                </VerifyPermission>

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <CreateEmailLink />
                    </VerifyPermission>
                )}

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <QueryLink />
                    </VerifyPermission>
                )}

                {chatFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <ChatLink />
                    </VerifyPermission>
                )}

                {proactiveChatEnabled && user?.isProactiveChatEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <NavigationLink icon={faCommentLines} page={View.ProactiveChat} label="Proactive Chat" />
                    </VerifyPermission>
                )}

                {emailFunctionalityEnabled && (
                    <VerifyPermission permission={Permission.HandleInteraction}>
                        <DeferredQueriesLink />
                    </VerifyPermission>
                )}

                <VerifyPermission permission={Permission.ViewReporting}>
                    <CategoryNavigation label="Reporting" icon={faChartBar}>
                        {LIVE_AGENTS_REPORTING_ENABLED && (
                            <NavigationLink icon={faUsers} page={View.ReportingAgents} label="Active Agents" />
                        )}
                        {chatFunctionalityEnabled && (
                            <NavigationLink icon={faComments} page={View.ReportingChat} label="Chat Queue" />
                        )}
                        {emailFunctionalityEnabled && (
                            <NavigationLink icon={faEnvelope} page={View.ReportingQueue} label="Email Queue" />
                        )}
                        <NavigationLink icon={faCalendar} page={View.ReportingHistoric} label="Historic" />
                        <NavigationLink icon={faFileChartLine} page={View.ReportingReports} label="Reports" />
                        {emailFunctionalityEnabled && (
                            <NavigationLink icon={faDesktop} page={View.ReportingPublicEmail} label="Email Wallboard" />
                        )}
                        {chatFunctionalityEnabled && (
                            <NavigationLink icon={faDesktop} page={View.ReportingPublicChat} label="Chat Wallboard" />
                        )}
                        {callFunctionalityEnabled && (
                            <NavigationLink icon={faDesktop} page={View.CallWallboard} label="Call Wallboard" />
                        )}
                    </CategoryNavigation>
                </VerifyPermission>

                <VerifyPermission permission={Permission.ViewSearch}>
                    <CategoryNavigation icon={faSearch} label="Search">
                        <NavigationLink icon={faComments} page={View.SearchInteractions} label="Interactions" />
                        <NavigationLink icon={faAddressBook} page={View.SearchQueries} label="Queries" />
                    </CategoryNavigation>
                </VerifyPermission>

                <VerifyPermission permission={SETTINGS_PERMISSIONS}>
                    <CategoryNavigation icon={faCog} label="Settings">
                        <VerifyPermission permission={Permission.EditAgentState}>
                            {MANAGE_USER_STATES_ENABLED && (
                                <NavigationLink icon={faUserTag} page={View.SettingsUserStates} label="Agent States" />
                            )}
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditWidget, Permission.ToggleWidget]}>
                            <NavigationLink icon={faListAlt} page={View.SettingsSetups} label="Chat Widgets" />
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditWidget]}>
                            <NavigationLink
                                icon={faComment}
                                page={View.SettingsChatWidgetAppearances}
                                label="Chat Widget Appearances"
                            />
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditChatBan]}>
                            <NavigationLink icon={faBan} page={View.SettingsBans} label="Chat Bans" />
                        </VerifyPermission>

                        {BRANDS_SCREEN_ENABLED && (
                            <VerifyPermission permission={[Permission.EditBrand]}>
                                <NavigationLink icon={faTrademark} page={View.SettingsBrands} label="Brands" />
                            </VerifyPermission>
                        )}

                        <VerifyPermission permission={[Permission.EditTheme]}>
                            <NavigationLink icon={faPaintRoller} page={View.SettingsThemes} label="Themes" />
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditLanguage]}>
                            <NavigationLink icon={faGlobeAmericas} page={View.SettingsLanguages} label="Languages" />
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditEmailRoutingRule]}>
                            <NavigationLink
                                icon={faEnvelope}
                                page={View.SettingsEmailRoutingRules}
                                label="Email Routing Rules"
                            />
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditDepartment]}>
                            <NavigationLink icon={faBuilding} page={View.SettingsDepartments} label="Departments" />
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditSkill]}>
                            <NavigationLink icon={faTags} page={View.SettingsSkills} label="Skills" />
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditTextLocalisation]}>
                            {TEXT_LOCALISATIONS_ENABLED && (
                                <NavigationLink
                                    icon={faLanguage}
                                    page={View.SettingsTextLocalisations}
                                    label="System Text"
                                />
                            )}
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditForm]}>
                            {FORMS_PAGE_ENABLED && (
                                <NavigationLink icon={faFile} page={View.SettingsForms} label="Forms" />
                            )}
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditSystemSetting]}>
                            <NavigationLink icon={faWrench} page={View.SettingsSystem} label="System" />
                        </VerifyPermission>

                        <VerifyPermission permission={[Permission.EditLogic]}>
                            <NavigationLink icon={faSitemap} page={View.LogicFlows} label="Flows" />
                        </VerifyPermission>
                    </CategoryNavigation>
                </VerifyPermission>

                <VerifyPermission permission={[Permission.ViewTemplate, Permission.EditTemplate]}>
                    <TemplatesLink />
                </VerifyPermission>

                <VerifyPermission permission={[Permission.EditUser, Permission.EditUserBasic, Permission.EditRole]}>
                    <CategoryNavigation icon={faUsers} label="User Management">
                        <VerifyPermission permission={Permission.EditRole}>
                            <NavigationLink icon={faUserTag} page={View.Permissions} label="Permissions" />
                        </VerifyPermission>
                        <VerifyPermission permission={[Permission.EditUser, Permission.EditUserBasic]}>
                            <UsersLink />
                        </VerifyPermission>
                    </CategoryNavigation>
                </VerifyPermission>

                <SignOut />
            </div>
        </div>
    );
};

export default Navigation;
